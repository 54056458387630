<template lang="pug">
  FeatureLayout
    h1.content__title Sign In
    Form(endpoint="auth/login", :data="data", @success="onLoginSuccess", button-text="SIGN IN")
      InputGroup(name="email", label="Email Address"): TextInput(v-model="data.email", prepend-icon="envelope", id="email")
      InputGroup(name="password", label="Password"): PasswordInput(v-model="data.password", prepend-icon="lock", id="password")
    p.content__links: router-link(:to="{name: 'auth.forgot-password'}") Forgot your password?

    template(v-slot:feature)
      img.feature__image(src="@/assets/images/auth/login.jpeg")
      div.feature__meta
        p: strong Burano
        p Venice, Italy
</template>

<script>
import { get } from "lodash-es"
import { FeatureLayout } from "@syntax51/app-kit"

export default {
  metaInfo: {
    title: "Login",
    bodyAttrs: {
      class: "page--login"
    }
  },
  components: {
    FeatureLayout
  },
  data: () => ({
    data: {
      email: "",
      password: ""
    }
  }),
  methods: {
    async onLoginSuccess({ user }) {
      await this.$store.dispatch("auth/login", user)

      let defaultRoute = { name: "dashboard" }

      if (!user.permissions["search.destination"]) {
        defaultRoute = { name: "collections" }
      }

      let redirect = get(this.$router, "currentRoute.query.redirect")
      let redirectTo = redirect || defaultRoute

      this.$router.push(redirectTo || "/dashboard")
    }
  }
}
</script>

<style>
.page--login {
  .content__inner {
    @apply mx-auto w-full;
    max-width: 450px;
  }
}
</style>
